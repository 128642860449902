
import { defineComponent, onMounted, reactive, ref } from 'vue'
import moment, { Moment } from 'moment'
import 'moment/dist/locale/zh-cn'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import {
  createProject,
  editProject,
  getTeamList,
  getProjectDetail,
  getProjecType
} from '@/api/index'
import { filterKeys } from '@/hooks/common-hooks'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
interface FormProps {
  name: string;
  type: number | null;
  project_num: string;
  plan_strat_time: string;
  plan_end_time: string;
  team_ids: number[];
  describe: string;
  issuer: string;
  remarks: string;
}
export default defineComponent({
  name: 'createProject',
  props: ['id'], // 项目id
  emits: ['changeIndex'],
  setup(props, context) {
    const router = useRouter()
    const formRef = ref()
    const date = ref<Moment[]>([])
    const teamList = ref()
    const typeList = ref()
    const formState: FormProps = reactive({
      name: '',
      type: 1,
      project_num: '',
      plan_strat_time: '',
      plan_end_time: '',
      team_ids: [],
      describe: '',
      issuer: '',
      remarks: ''
    })
    const rules = {
      name: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入项目名称',
          whitespace: true
        }
      ],
      project_num: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入项目编号',
          whitespace: true
        }
      ],
      // date: [{ required: true, trigger: 'change', type: 'array', validator: checkSelectList, message: '请选择规划时间', whitespace: true }],
      team_ids: [
        {
          required: true,
          trigger: 'change',
          type: 'array',
          message: '请选择班组',
          whitespace: true
        }
      ],
      describe: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入描述',
          whitespace: true
        }
      ],
      issuer: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入发令人',
          whitespace: true
        }
      ]
    }
    // 获取项目类型
    const getTypeList = () => {
      getProjecType({}).then(res => {
        typeList.value = res.data
      })
    }
    // 获取班组列表
    const getTeamLists = () => {
      getTeamList({}).then(res => {
        teamList.value = res.data
      })
    }
    const getTeam = () => {
      return teamList.value.filter((item: any) => {
        return formState.team_ids.indexOf(item.id) >= 0
      }).map((item: any) => {
        return {
          id: item.id,
          major_id: item.major_id
        }
      })
    }
    // 创建
    const fetchCreatProject = () => {
      createProject(
        Object.assign(formState, {
          plan_strat_time: date.value[0],
          plan_end_time: date.value[1],
          team_ids: getTeam()
        })
      ).then(res => {
        message.success('创建成功')
        context.emit('changeIndex', 0)
      })
    }
    // 编辑时检验规划结束时间是否到期
    const isDate = () => {
      const curentDate = new Date()
      const time = curentDate.valueOf()
      const endTime = date.value[1].valueOf()
      console.log(time, 'time')
      console.log(endTime, 'endTime')
      return endTime > time
    }
    // 编辑
    const fetchEditProject = () => {
      if (isDate()) {
        editProject(
          Object.assign(formState, {
            id: props.id,
            plan_strat_time: date.value[0],
            plan_end_time: date.value[1],
            team_ids: getTeam()
          })
        ).then(res => {
          console.log(res)
          message.success('修改项目信息成功')
          router.replace({ name: 'ProjectManger' })
        })
      } else {
        message.error('规划结束时间小于当前时间,请重新选择规划结束时间')
      }
    }
    const onSubmit = (callback: () => {}) => {
      formRef.value
        .validate()
        .then(() => {
          callback()
        })
        .catch((error: ValidateErrorEntity<FormProps>) => {
          console.log('error', error)
        })
    }
    // 获取绑定数据
    const getProjectDetailHttp = () => {
      getProjectDetail({ id: props.id }).then(res => {
        console.log(res)
        filterKeys(formState, res.data)
        date.value[0] = moment(res.data.plan_strat_time)
        date.value[1] = moment(res.data.plan_end_time)
        formState.team_ids = res.data.teams.map((item: any) => item.team_id)
        console.log(date)
      })
    }
    const disabledDate = (current: any) => {
      return current && current < moment().startOf('day')
    }
    const range = (start: any, end: any) => {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }
    const disabledRangeTime = (_: any, type: any) => {
      if (type === 'start') {
        return {
          disabledHours: () => range(0, moment().get('hour') + 2),
          disabledMinutes: () => range(0, 60).filter(item => item % 10 !== 0)
        }
      }
    }
    onMounted(() => {
      getTypeList()
      getTeamLists()
      if (props.id) {
        getProjectDetailHttp()
      }
    })
    return {
      formRef,
      zhCN,
      date,
      formState,
      rules,
      onSubmit,
      fetchCreatProject,
      fetchEditProject,
      teamList,
      disabledDate,
      disabledRangeTime,
      moment,
      typeList
    }
  }
})
